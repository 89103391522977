import MyButton from '#app/components/ui/my-button.js'

const Home = () => {
  return (
		<div>
			<div
				className='section justify-center pb-5 pt-10 md:pt-20'
				aria-label='home page content section'
			>
				<div className='container flex-col justify-center'>
					<h1 className='home-page-h1'>
						Ramona Ridgewell
					</h1>
					<h2
						className='home-page-h2'
					>
						Author, Poet, Editor
					</h2>
				</div>
				<div className='container flex justify-center'>				
					<div className='button-grid'>
						<MyButton
							buttonContainerProps='home-page-button-container'
							buttonProps='home-page-button'
							buttonName='Seattle Vampire Tales'
							routeName='seattle-vampire-tales'
						/>
						<MyButton
							buttonContainerProps='home-page-button-container'
							buttonProps='home-page-button'
							buttonName='More Publications'
							routeName='more-publications'
						/>
						<MyButton
							buttonContainerProps='home-page-button-container'
							buttonProps='home-page-button'
							buttonName='Intrepid Turtle Press'
							routeName='intrepid-turtle-press'
						/>
						<MyButton
							buttonContainerProps='home-page-button-container'
							buttonProps='home-page-button'
							buttonName='Two Hour Transport'
							routeName='two-hour-transport'
						/>
						<MyButton
							buttonContainerProps='home-page-button-container'
							buttonProps='home-page-button'
							buttonName='About Me'
							routeName='about-me'
						/>
						<MyButton
							buttonContainerProps='home-page-button-container'
							buttonProps='home-page-button'
							buttonName='Contact Me'
							routeName='contact-me'
						/>
					</div>
				</div>
			</div>
		</div>
  )
}

export default Home